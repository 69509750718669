<template>
  <!-- <Header type="regular" /> -->

  <!--  -->
  <div class="layout">
    <!-- <HomeHero /> -->

    <div class="content-wrapper container gx-10">
      <Subheader :type="'multisig'" />
      <div class="mt-5">
        <div
          class="row"
          v-if="companies && Object.keys(companies).length && metamaskAddress"
        >
          <div class="col-12">
            <h2 class="section-heading mt-4 mb-4 pb-2">Ten31 Bank shares</h2>
          </div>
          <div
            :key="index"
            v-for="(balance, index) in balances"
            class="col-12 col-lg-6 col-xxl-4"
          >
            <div class="token-card position-relative">
              <div
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <h5 class="mb-3 bold">{{ balance.tokenName }}</h5>
                <p v-if="balance.voteCount > 0 && !hideVoteCount" class="pending-votes">
                  {{ balance.voteCount ? balance.voteCount : "0" }}
                  <span class="hide-mobile">Pending votes</span>
                </p>
              </div>
              <h6>{{ (balance.tokenAddress) }}</h6>
              <h3 class="mt-2 mb-4">
                <img :src="btcIcon" alt="" />
                <span class="d-inline-block mx-2">{{ balance.balance }}</span>
                <small>{{ balance.tokenSymbol }}</small>
              </h3>
              <div class="d-flex buttons-wrapper mt-1">
                <button
                  @click="openVoteModal(balance.tokenAddress)"
                  class="button white"
                >
                  Vote
                </button>
                <button
                  class="button white"
                  @click="displayProposeModal(balance.tokenAddress)"
                >
                  Propose
                </button>
                <button @click="displayReceiveModal" class="button white">
                  Receive
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- END NEW VOTE CARDS -->
      </div>

      <!-- {{multisigToken.tokenName}} {{multisigToken.tokenSymbol}} -->
      <div
        class="
          d-flex
          justify-content-between
          mt-5
          d-flex
          align-items-center
          flex-wrap
        "
      >
        <h4
          ref="transactions-history-title"
          class="bold section-heading transactions-heading"
        >
          Transactions History
        </h4>
        <div class="filters-wrapper d-flex justify-content-end">
          <select v-model="filters.time" class="ms-1 me-2">
            <option selected value="all-time">All time</option>
            <option value="last-day">Today</option>
            <option value="last-week">Last week</option>
            <option value="last-month">Last month</option>
          </select>
          <select v-model="filters.type" class="ms-1 me-2">
            <option selected value="all">All types</option>
            <option value="send">Sent</option>
            <option value="receive">Received</option>
          </select>
          <select
            v-if="
              companies &&
              companies[chosenCompany] &&
              companies[chosenCompany].tokens
            "
            v-model="filters.token"
            class="ms-1"
          >
            <option selected value="all">All tokens</option>
            <option
              :key="index"
              v-for="(token, index) of companies[chosenCompany].tokens"
              :value="token.tokenAddress"
            >
              {{ token.tokenSymbol }}
            </option>
          </select>
        </div>
      </div>
      <div class="my-5" v-if="!tokenTransactions?.length">
        <p class="text-center">No transactions.</p>
      </div>
      <template v-else>
        <div class="row">
          <div class="col-12">
            <div
              class="token-transaction"
              :key="index"
              v-for="(transaction, index) of tokenTransactions"
            >
              <div class="transaction-section d-flex align-items-center">
                <img class="me-2" :src="clockIcon" alt="" /><span>{{
                  transaction.created_at.replace("T", " ").slice(0, 19)
                }}</span>
              </div>
              <div class="transaction-section d-flex align-items-center">
                <span>{{
                  transaction.to_address.toLowerCase() ===
                  metamaskAddress.toLowerCase()
                    ? "Received"
                    : "Sent"
                }}</span>
              </div>
              <div
                class="transaction-section from-to d-flex align-items-center"
              >
                <span
                  class="transaction-address"
                  :title="transaction.from_address"
                  >{{ shortenAddress(transaction.from_address, 7) }}</span
                >
                <img class="mx-3" :src="arrowRight2" alt="" />
                <span
                  class="transaction-address"
                  :title="transaction.to_address"
                  >{{ shortenAddress(transaction.to_address, 7) }}</span
                >
              </div>
              <div class="transaction-section d-flex align-items-center">
                <img class="me-1" :src="fiveIcon" alt="" />

                <span
                  v-if="
                    companies &&
                    companies[chosenCompany] &&
                    companies[chosenCompany]?.tokens[transaction.address]
                  "
                  >{{ transaction.amount }}
                  {{
                    companies[chosenCompany]?.tokens[transaction.address]
                      ?.tokenSymbol
                  }}
                </span>
              </div>
              <div class="transaction-section d-flex align-items-center">
                <a
                  class="button white py-2"
                  :href="`https://kovan.etherscan.io/tx/${transaction.transaction_hash}`"
                >
                  Details
                </a>
              </div>
            </div>
            <div
              class="
                pagination-wrapper
                d-flex
                align-items-center
                justify-content-center
                mb-5
                pb-3
              "
            >
              <button
                :disabled="currentPage === 1"
                @click="getTransactions(firstPage, true)"
                class="button white"
              >
                &lt;&lt; <span>First</span>
              </button>
              <button
                :disabled="!prevPage"
                @click="prevPage && getTransactions(Number(prevPage), true)"
                class="button white"
              >
                &lt;<span>Previous</span>
              </button>
              <span class="pagination-current"
                >{{ currentPage }} / {{ lastPage }}</span
              >
              <button
                :disabled="!nextPage"
                @click="nextPage && getTransactions(Number(nextPage), true)"
                class="button white"
              >
                <span>Next</span> &gt;
              </button>
              <button
                :disabled="lastPage == currentPage"
                @click="lastPage && getTransactions(Number(lastPage), true)"
                class="button white"
              >
                <span>Last</span> &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <div
    @click="closeModal"
    :class="
      showMultisigModal || showVoteModal || showReceiveModal || showProposeModal
        ? 'active'
        : ''
    "
    class="modal-wrapper"
  >
    <div
      @click.stop
      :class="showMultisigModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">×</div>
      <div class="w-100">
        <h4 class="text-center mb-4">Import Contract</h4>
        <p class="modal-text text-center mb-4">
          Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
          ultrices diam sollicitudin condimentum integer ultrices diam
          suspendisse rhoncus.
        </p>
        <label for="proposal-address">Address</label>
        <input
          id="proposal-address"
          placeholder="Enter contract address..."
          v-model="proposal.address"
          type="text"
        />
        <button
          :disabled="!addressInput.length"
          @click="submitContract"
          class="button w-100 mt-3"
        >
          Submit
        </button>
      </div>
    </div>

    <div
      @click.stop
      :class="showReceiveModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">×</div>
      <div v-if="metamaskAddress" class="modal-content">
        <h4 class="text-center">Receive</h4>
        <p class="modal-text text-center mb-0">
          Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
          ultrices diam sollicitudin condimentum integer ultrices diam
          suspendisse rhoncus.
        </p>
        <img :src="qrUrl" class="mt-4 mb-2" alt="" />
        <label for="address">Address</label>
        <input class="mb-4" disabled :value="metamaskAddress" type="text" />
        <button @click="copyAddress" class="button w-100 mt-3">Copy</button>
      </div>
      <div class="w-100" v-else>
        <h4 class="text-center mb-4">Receive</h4>
        <button @click="metamaskSetup" class="button w-100">
          Connect wallet
        </button>
      </div>
    </div>

    <div
      @click.stop
      :class="showProposeModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">×</div>
      <div class="modal-content proposal-modal">
        <h4 class="text-center">Propose</h4>
        <p class="modal-text text-center">
          Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
          ultrices diam sollicitudin condimentum integer ultrices diam
          suspendisse rhoncus.
        </p>
        <div class="form-group w-100 mt-3 mb-0">
          <label for="propose-token">Token</label>
          <select id="propose-token" v-model="proposal.token">
            <option class="d-none" selected value="">Select token</option>
            <option
              :key="index"
              v-for="(balance, index) of balances"
              :value="balance.tokenAddress"
            >
              {{ balance.tokenName }}
            </option>
          </select>
        </div>
        <div class="form-group w-100 mt-3 mb-0">
          <label for="propose-address">Address</label>
          <input
            placeholder="Enter address here..."
            id="propose-address"
            v-model="proposal.address"
            type="text"
          />
          <p
            v-if="proposalAddressEmpty"
            class="error-msg mb-0 d-block w-100 text-left"
          >
            Please enter address
          </p>
        </div>
        <div class="position-relative">
          <div class="form-group w-100 mt-3 mb-0">
            <label for="propose-address">Amount</label>
            <input
              placeholder="Enter amount here..."
              id="propose-address"
              v-model="proposal.amount"
              type="text"
            />
            <p v-if="proposalAmountEmpty" class="error-msg mb-0">
              Please enter amount
            </p>
            <p v-if="invalidAmount" class="error-msg mb-0">
              Please enter a whole number
            </p>
            <p v-if="amountNegative" class="error-msg mb-0">
              Amount must be more than 0
            </p>
          </div>
          <!-- <button @click="getMaxAmount" class="max-btn">Max</button> -->
        </div>
        <button
          :disabled="disablePropose"
          @click="proposeExecution"
          class="button mt-3"
        >
          <div v-if="disablePropose" class="custom-spinner"></div>
          <span v-else>Propose</span>
        </button>
      </div>
    </div>

    <div
      @click.stop
      :class="showVoteModal && 'active'"
      class="modal voting position-relative"
    >
      <div @click="closeModal" class="close-modal">×</div>
      <div class="w-100">
        <template v-if="!voteChosen">
          <h4 class="text-center mb-4">Pending Votes</h4>
          <p class="modal-text text-center mb-4">
            Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
            ultrices diam sollicitudin condimentum integer ultrices diam
            suspendisse rhoncus.
          </p>
          <template v-if="loadingVotes">
            <div
              class="
                modal-loader-wrapper
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <div class="loader"></div>
            </div>
          </template>
          <template v-else-if="votes && !loadingVotes">
            <div
              @click="chooseVote(vote)"
              class="pending-vote-item d-flex justify-content-between"
              :key="index"
              v-for="(vote, index) of votes"
            >
              <div class="pending-vote-left d-flex align-items-center">
                <img class="me-2" :src="clockIcon" alt="" />
                <span>{{ calcRemainingTime(vote.voteFinishTimestamp) }}</span>
              </div>
              <div class="pending-vote-right d-flex align-items-center">
                <span
                  >{{ getInt(`0x${vote.params.slice(-64)}`) }}
                  {{
                    companies[chosenCompany].tokens[vote[1]].tokenSymbol
                  }}</span
                >
                <img class="mx-2" :src="arrowRight2" alt="" />
                <span>{{
                  shortenAddress(`0x${vote.params.slice(26, 66)}`)
                }}</span>
              </div>
            </div>
          </template>
          <template v-if="!Object.keys(votes).length && !loadingVotes">
            <p class="text-center modal-text mt-4 mb-5">
              No proposals on this token yet
            </p>
          </template>

          <button @click="closeModal" class="button w-100 mt-3">Close</button>
        </template>
        <template v-else>
          <p @click="voteChosen = false" class="vote-modal-back">
            <img :src="arrowRight" alt="" /> <span>Back</span>
          </p>
          <h4 class="text-center mb-4">Vote</h4>
          <p class="modal-text text-center mb-4">
            Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
            ultrices diam sollicitudin condimentum integer ultrices diam
            suspendisse rhoncus.
          </p>
          <div class="vote-info">
            <div class="vote-info-row d-flex">
              <img class="me-2" :src="clockIcon" alt="" />
              <p>
                <span class="light-text">Expiry: </span>
                {{ formatHexDate(chosenVote.voteFinishTimestamp) }}
              </p>
            </div>
            <div class="vote-info-row d-flex">
              <img class="me-2" :src="walletIcon" alt="" />
              <p>
                <span class="light-text">Address: </span
                >{{ `0x${chosenVote.params.slice(26, 66)}` }}
              </p>
            </div>
            <div class="vote-info-row d-flex">
              <img class="me-2" :src="fiveIcon" alt="" />
              <p>
                <span class="light-text">Amount: </span>
                {{ getInt(`0x${chosenVote.params.slice(-64)}`) }}
              </p>
            </div>
            <div class="vote-info-row d-flex align-items-center">
              <img class="me-2" :src="thumbIcon" alt="" />
              <p>
                <span class="light-text">Votes:</span>
                {{ chosenVote.voteAmount }} / {{ voteThreshold }}
              </p>
            </div>
          </div>
          <div
            v-if="
              calcRemainingTime(chosenVote.voteFinishTimestamp) !==
                'Vote time expired' && !chosenVote.voteFinished
            "
            class="vote-button-wrapper d-flex mt-5 w-100"
          >
            <button
              :disabled="disableCast"
              @click="castVote(chosenVote.id)"
              class="button"
            >
              Cast vote
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>

  <!-- <Footer /> -->
</template>


<script lang="js">
import { defineComponent } from "vue";
// import HomeHero from "../../components/HomeHero.vue";
import Subheader from "../../components/Subheader.vue";
import { multisigRegistrySetup, castVote, getVotes, getVoteCount, getVoteThreshold } from '../../main.ts';
import { shortenAddress, getAmountFromInput } from '../../helpers.js';
import btcIcon from '../../assets/images/btc.svg';
import clockIcon from '../../assets/images/clock.svg';
import fiveIcon from '../../assets/images/5.svg';
import walletIcon from '../../assets/images/wallet.svg';
import thumbIcon from '../../assets/images/thumb-up.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import arrowRight2 from '../../assets/images/arrow-right2.svg';
import { proposeExecution } from "../../main";
import QRCode from 'qrcode';
import config from '../../config';
import { BigNumber } from 'ethers';
import moment from 'moment';

export default defineComponent({
  name: "Home",
  components: {
    // HomeHero,
    Subheader,
  },
  data() {
        return {
            hideVoteCount: false,
            disableCast: false,
            voteThreshold: null,
            amountNegative: false,
            proposalAmountEmpty: false,
            invalidAmount: false,
            proposalAddressEmpty: false,
            disablePropose: false,
            loadingVotes: true,
            body: null,
            chosenVote: null,
            balances: null,
            contractAddress: "",
            showMultisigModal: false,
            multisigVersion: null,
            canUseMultisig: null,
            addressInput: '',
            votes: [],
            totalVotes: null,
            metamaskAddress: null,
            disabledVoteIds: [],
            hasSubmitted: false,
            multisigToken: null,
            showVoteModal: false,
            showReceiveModal: false,
            showProposeModal: false,
            clockIcon: clockIcon,
            fiveIcon: fiveIcon,
            walletIcon: walletIcon,
            thumbIcon: thumbIcon,
            btcIcon: btcIcon,
            arrowRight2: arrowRight2,
            arrowRight: arrowRight,
            noCompanies: false,
            voteChosen: false,
            tokenTransactions: [],
            chosenCompany: 0,
            qrUrl: '',
            firstLoad: true,
            companies: {},
            chosenToken: null,
            currentTime: null,
            filters: {
                time: 'all-time',
                type: 'all',
                token: 'all'
            },
            proposal: {
                address: '',
                token: '',
                amount: ''
            },
            unsubscribe: null
        };
  },
  mounted() {
    this.currentTime = new Date().getTime();
    this.body = document.querySelector('body');
    if (!this.$store.state.multisigParticipants) {
      multisigRegistrySetup(this.$store.state.chosenCompany, this.$store.state.metamaskAddress);
    } else {
      this.multisigParticipants = this.$store.state.multisigParticipants;
    }
    this.$store.watch(state => {
        console.log(state.tokenTransactions);
        this.multisigToken = state.multisigToken;
        this.multisigVersion = state.multisigVersion;
        this.canUseMultisig = state.canUseMultisig;
        this.totalVotes = state.totalVotes;
        this.metamaskAddress = state.metamaskAddress;
        this.companies = state.companies;
        this.chosenCompany = state.chosenCompany;
        /* eslint-disable */
        return;
        if (state.chosenMultisigContract) {
          this.chosenMultisigContract = state.chosenMultisigContract;
        }
        if (state.metamaskAddress) {
            this.metamaskAddress = state.metamaskAddress;
            QRCode.toDataURL(state.metamaskAddress, {scale: 8, color: {
              dark: '#3F4C62'
            }})
            .then((url) => {
                this.qrUrl = url;
            })
            .catch((err) => {
                console.error(err);
            });
        } else {
            this.metamaskAddress = state.metamaskAddress;
        }
        if (state.multisigContracts ) {
            this.multisigContracts = state.multisigContracts[1];
        }
    });
    this.unsubscribe = this.$store.subscribe(async(mutation, state) => {
        if (!state.companies) {
            this.noCompanies = true;
        } else {
            const balances = {};
            Object.keys(state.companies).forEach(companyId => {
                Object.keys(state.companies[companyId].tokens).forEach(tokenAddr => {
                    if (state.chosenCompany == companyId) {
                        balances[tokenAddr] = state.companies[companyId].tokens[tokenAddr];
                    }
                });
            });
            this.balances = balances;
            this.proposal.token = Object.keys(balances)[0];
            this.companies = state.companies;
        }
        if (mutation.type === 'setChosenCompany') {
            this.chosenCompany = state.chosenCompany;
            multisigRegistrySetup(state.chosenCompany, this.$store.state.metamaskAddress);
        }
        if (mutation.type === 'setChosenMultisigContract') {
          this.hideVoteCount = true;
          this.chosenMultisigContract = state.chosenMultisigContract;
          for (const key in this.balances) {
            console.log(this.chosenMultisigContract, key);
            const voteCount = await getVoteCount(this.chosenMultisigContract, key); //get PENDING vote count when method is ready
            const voteThreshold = await getVoteThreshold(this.chosenMultisigContract);
            this.voteThreshold = voteThreshold.toNumber();
            this.balances[Object.keys(voteCount)[0]].voteCount = voteCount[Object.keys(voteCount)[0]];
            this.hideVoteCount = false;
          }
        }
        // if (mutation.type === 'setChosenCompany') {
        //   this.getTransactions();
        // }
    });
    if (this.$store?.state?.companies) {
        this.getTransactions();
    }
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    formatHexDate(hexTimestamp) {
      const timeStamp = BigNumber.from(hexTimestamp).toNumber() * 1000;
      const momentDate = moment(timeStamp);
      return momentDate;
      // console.log(momentDate);
    },
    displayReceiveModal() {
      this.showReceiveModal = true;
      this.body.style.overflow = 'hidden';
    },
    calcRemainingTime(hexTimestamp) {
      const timeStamp = BigNumber.from(hexTimestamp).toNumber() * 1000;
      const remainder = moment.duration(moment(timeStamp).diff(moment()));
      if (remainder.days() > 0) {
        return `${Math.abs(remainder.days())} days remaining`;
      } else if (remainder.days() <= 0 && remainder.hours() > 0) {
        return `${remainder.hours()} hours remaining`;
      } else if (remainder.hours() <= 0 && remainder.minutes() > 0) {
        return `${remainder.minutes()} minutes remaining`;
      } else if (remainder.minutes() <= 0 && remainder.seconds() > 0) {
        return `${remainder.seconds()} seconds remaining`;
      } else {
        return 'Vote time expired';
      }
    },
    getInt(hexNum) {
      return BigNumber.from(hexNum).toNumber();
    },
    proposeExecution() {
      let validity = 0;
      if (!this.proposal.amount) {
        this.proposalAmountEmpty = true;
        validity++;
      } else {
        this.proposalAmountEmpty = false;
      }
      if (this.proposal.amount && !this.proposal.amount.match(/^(-*[0-9])*$/g)) {
        this.invalidAmount = true;
        validity++;
      } else {
        this.invalidAmount = false;
      }
      if (this.proposal.amount && Number(this.proposal.amount) <= 0) {
        this.amountNegative = true;
        validity++;
      } else {
        this.amountNegative = false;
      }
      if (!this.proposal.address) {
        this.proposalAddressEmpty = true;
        validity++;
      } else {
        this.proposalAddressEmpty = false;
      }
      if (validity) {
        return;
      }
      this.disablePropose = true;
      proposeExecution(this.chosenMultisigContract, this.proposal.address, this.proposal.amount, this.proposal.token)
        .then(res => {
            if (res.hash) {
                this.$toast.success('Proposal successful!');
                this.closeModal();
                setTimeout(async() => {
                  for (const key in this.balances) {
                    console.log(this.chosenMultisigContract, key);
                    const voteCount = await getVoteCount(this.chosenMultisigContract, key);
                    console.log(voteCount);
                    this.balances[Object.keys(voteCount)[0]].voteCount = voteCount[Object.keys(voteCount)[0]];
                  }
                }, 1000);
            } else {
                this.$toast.error('Proposal failed, try again');
            }
            this.disablePropose = false;
        })
        .catch(error => {
            console.log(error.code);
            if (error.code == 4001) {
              this.$toast.error('You rejected the transaction');
            } else {
              this.$toast.error(error.toString().slice(0, error.toString().indexOf('(')));
            }
            this.disablePropose = false;
        });
    },
    copyAddress() {
        navigator.clipboard.writeText(this.metamaskAddress);
        this.$toast.success('Copied address!');
    },
    async openVoteModal(tokenAddress) {
      this.loadingVotes = true;
      this.votes = {};
      this.body.style.overflow = 'hidden';
      this.showVoteModal = true;
      console.log()
      let currentVotes = await getVotes(this.chosenMultisigContract, tokenAddress);
      currentVotes = currentVotes.filter((item, index) => {
        if (index === 0 && item[3].length > 5) {
          return item;
        } else {
          return item.id.toNumber() > 0;
        }
      });
      this.votes = currentVotes;
      this.chosenToken = tokenAddress;
      this.loadingVotes = false;
    },
    displayProposeModal(tokenAddress) {
      this.body.style.overflow = 'hidden';
      this.proposal.token = tokenAddress;
      this.showProposeModal = true;
    },
    getMaxAmount() {
      this.proposal.amount = 1000000000;
    },
    // handleVote(toAddress, inputAmount) {
    //   proposeExecution(this.toAddress, this.inputAmount)
    //     .then((res) => {
    //       console.log("proposal", res);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getAmountFromInput(input) {
        return getAmountFromInput(input);
    },
    shortenAddress(string) {
        return shortenAddress(string);
    },
    async getTransactions(pageNum = 1, scrollToTop = false) {
      console.log('GETTING TRANSACTIONS...');
        this.tokenTransactions = null;
        this.transactionsLoading = true;
        const tokensArr = [];
        if (this.filters.token === 'all') {
          this.companies[this.chosenCompany] &&  Object.keys(this.companies[this.chosenCompany]?.tokens).forEach(tokenAddr => tokensArr.push(tokenAddr));
        } else {
          tokensArr.push(this.filters.token);
        }
        try {
            const data = await fetch(`${config.API}/api/v1/token-transactions?page=${pageNum}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_address: this.metamaskAddress,
                    type: this.filters.type,
                    from_date: this.filters.time,
                    topic: '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
                    token_addresses: tokensArr
                })
            })
            .then(res => res.json())
            .catch(error => console.log(error));
            this.tokenTransactions = data.data.token_transactions.data;
            this.prevPage = data.data.token_transactions?.prev_page_url
                ? Number(data.data.token_transactions.current_page) - 1
                : null;
            this.nextPage = data.data.token_transactions?.next_page_url
                ? Number(data.data.token_transactions.current_page) + 1
                : null;
            this.lastPage = data.data.token_transactions?.last_page;
            this.firstPage = data.data.token_transactions?.last_page;
            this.currentPage = data.data.token_transactions?.current_page;
            this.transactionsLoading = false;
            if (scrollToTop) {
                setTimeout(() => {
                    const transactionsTitle = this.$refs['transactions-history-title'];
                    const top = transactionsTitle.offsetTop;
                    window.scrollTo(0, top - 300);
                }, 50);
            }
        } catch(error) {
            console.log(error);
        }
    },
    // async submitContract() {
    //     this.closeModal();
    //     const res = await multisigSetup(this.contractInput);
    //     this.hasSubmitted = true;
    //     if (res?.error) {
    //         this.canUseMultisig = false;
    //     }
    // },
    async castVote(id) {
      this.disableCast = true;
      try {
        let voteRes = await castVote(this.chosenMultisigContract, this.chosenToken, id);
        if (voteRes) {
          this.$toast.success('Vote cast successfully');
        }
      } catch(error) {
        this.disableCast = false;
        if (error.data.message) {
          this.$toast.error(error.data.message.slice(error.data.message.indexOf('revert') + 7));
        } else {
          this.$toast.error('An unexpected error occurred, please try again');
        }
      }
    },
    chooseVote(vote) {
      this.voteChosen = true;
      this.chosenVote = vote;
    },
    closeModal() {
      this.disableCast = false;
      this.body.style.overflow = 'auto';
      this.showMultisigModal = false;
      this.showVoteModal = false;
      this.showProposeModal = false;
      this.showReceiveModal = false;
      this.voteChosen = false;
    },
    handleFormSubmit() {
        // ajax call here
        // console.log("submit!");
    },
    handleAddressSubmit() {
      //address submit ajax
    },
    confirmProposal() {
      //confirm proposal ajax
    },
  },
  watch: {
    disabledVoteIds: function(newValue) {
        console.log('new', newValue);
    },
    'filters.time': function() {
      this.getTransactions();
    },
    'filters.type': function() {
      this.getTransactions();
    },
    'filters.token': function() {
      this.getTransactions();
    }
  }
});
</script>
